<template>
  <div class="content-wrapper pt-6 pb-12 secondary--text">
    <div class="slide-fade-y-enter enter-delay-150ms">
      <div class="text-body-1 text-md-h5 text-h6 font-weight-bold">
        {{ title }}
      </div>
      <p class="py-md-5 py-2 text-small text-sm-body-2 text-md-body-1">
        {{ subTitle }}
      </p>
    </div>
    <div>
      <v-row
        class="slide-fade-y-enter enter-delay-150ms"
        align="start"
        justify="start"
      >
        <v-col
          v-for="(i, index) in recipeList"
          :key="index"
          :cols="isMobile ? 6 : 3"
          class="pa-md-5"
        >
          <router-link
            :to="{
              name: getRecipeDetailsRouteName(i.category),
              params: { id: i.id }
            }"
          >
            <img :src="i.thumbnail" alt="" width="100%" />
            <div
              class="py-2 font-weight-bold text-md-h6 text-h7"
              style="overflow-wrap: anywhere"
            >
              {{ i.name }}
            </div>
            <p class="py-1 ma-0 text-small text-sm-body-2 text-md-body-1">
              {{ i.price }}
            </p>
            <p class="ma-0 text-small text-sm-body-2 text-md-body-1">
              {{ i.creatorName }}
            </p>
          </router-link>
        </v-col>
      </v-row>
      <div>
        <h1
          class="font-caveat secondary--text text-center caveat--medium--text"
        >
          {{
            $t(
              `label.${
                type == 3
                  ? 'muchMoreRecipesAreInBaking'
                  : 'muchMoreDishesAreInCooking'
              }`
            )
          }}
        </h1>
        <div v-if="isMobile" class="py-lg-10 py-5">
          <v-pagination
            v-model="recipePagination.currentPage"
            :length="recipePagination.lastPage"
            :total-visible="7"
            color="secondary"
            circle
            @input="onClickChangePage"
          ></v-pagination>
          <div class="d-flex justify-space-between">
            <v-btn
              @click="onClickChangePage(recipePagination.currentPage - 1)"
              class="rounded-xl font-weight-bold primary secondary--text text-small"
              :disabled="recipePagination.currentPage == 1"
              small
            >
              <i v-if="isMobile" class="fa-solid fa-chevron-left"></i>
              <span v-else>BACK</span>
            </v-btn>
            <v-btn
              @click="onClickChangePage(recipePagination.currentPage + 1)"
              class="rounded-xl font-weight-bold primary secondary--text text-small"
              :disabled="
                recipePagination.currentPage == recipePagination.lastPage
              "
              small
            >
              <i v-if="isMobile" class="fa-solid fa-chevron-right"></i>
              <span v-else>NEXT</span>
            </v-btn>
          </div>
        </div>
        <v-row v-else class="justify-space-around align-center py-lg-10 py-5">
          <v-btn
            @click="onClickChangePage(recipePagination.currentPage - 1)"
            class="rounded-xl font-weight-bold primary secondary--text text-small"
            :disabled="recipePagination.currentPage == 1"
            small
          >
            <i v-if="isMobile" class="fa-solid fa-chevron-left"></i>
            <span v-else>BACK</span>
          </v-btn>
          <div>
            <v-pagination
              v-model="recipePagination.currentPage"
              :length="recipePagination.lastPage"
              :total-visible="7"
              color="secondary"
              circle
              @input="onClickChangePage"
            ></v-pagination>
          </div>
          <v-btn
            @click="onClickChangePage(recipePagination.currentPage + 1)"
            class="rounded-xl font-weight-bold primary secondary--text text-small"
            :disabled="
              recipePagination.currentPage == recipePagination.lastPage
            "
            small
          >
            <i v-if="isMobile" class="fa-solid fa-chevron-right"></i>
            <span v-else>NEXT</span>
          </v-btn>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixin/common.mixin';
  import intersectMixin from '@/core/mixin/intersect.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { RecipeType } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    RECIPE_GET_RECIPES,
    RECIPE_INITIAL_GET_RECIPES_STATE
  } from '@/core/store/recipe.module';

  export default {
    name: 'RecipesList',
    mixins: [commonMixin, intersectMixin],
    props: {
      type: {
        type: Number,
        defualt: null
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      title: '',
      subTitle: '',
      isShowSection: false,
      recipeList: null,
      recipePagination: {
        currentPage: 1,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    }),
    created() {
      this.initailRecipesList();
    },
    mounted() {
      this.onIntersectActionEnterYOnce();
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },
      recipesComplete() {
        return this.$store.state.recipe.recipes.complete;
      }
    },
    watch: {
      recipesComplete() {
        let response = this.$store.state.recipe.recipes;
        if (response.complete) {
          this.recipeList = response.data;
          this.recipePagination = response.pagination;
          this.recipesCompleteAction(response);
        }
      }
    },
    methods: {
      onClickChangePage(value) {
        this.recipePagination.currentPage = value;
        this.getRecipes();
        window.scrollTo(0, 0);
      },
      recipesCompleteAction(response) {
        let type = null;
        let buttons = [];
        let title = i18nHelper.getMessage('label.recipes');
        let message = response.message;

        this.initialGetRecipesState();
        buttons = [
          {
            color: 'secondary',
            text: i18nHelper.getMessage('label.ok'),
            action: () => {
              this.closeAppDialogInfo();
            }
          }
        ];
        if (response.code == 0) {
          type = 'success';
        } else {
          type = 'error';
        }
        if (message) this.openAppDialogInfo(type, title, message, buttons);
      },
      initailRecipesList() {
        // this.recipeList = this.isMobile ? 6 : 12;
        this.getRecipeTypeTitle(this.type);
        this.getRecipes();
      },
      getRecipeDetailsRouteName(type) {
        let routeName = {
          [RecipeType.BAKERY]: ROUTE_NAME.RECIPE_BAKERY_DETAILS,
          [RecipeType.ASIAN]: ROUTE_NAME.RECIPE_ASIAN_DETAILS,
          [RecipeType.WESTERN]: ROUTE_NAME.RECIPE_WESTERN_DETAILS
        };
        return routeName[type];
      },
      getRecipeTypeTitle(type) {
        let title = {
          [RecipeType.BAKERY]: i18nHelper.getMessage(
            'messageRecipe.iWantBakeMoreList'
          ),
          [RecipeType.ASIAN]: i18nHelper.getMessage(
            'messageRecipe.iWantCookMoreList'
          ),
          [RecipeType.WESTERN]: i18nHelper.getMessage(
            'messageRecipe.iWantCookMoreList'
          )
        };
        let subTitle = {
          [RecipeType.BAKERY]: i18nHelper.getMessage(
            'messageRecipe.subTitleBakery'
          ),
          [RecipeType.ASIAN]: i18nHelper.getMessage(
            'messageRecipe.subTitleAsian'
          ),
          [RecipeType.WESTERN]: i18nHelper.getMessage(
            'messageRecipe.subTitleWestern'
          )
        };
        this.title = title[type];
        this.subTitle = subTitle[type];
      },
      getRecipes() {
        let data = {
          category: this.type,
          perPage: this.isMobile ? 6 : 12,
          currentPage: this.recipePagination.currentPage
        };
        this.$store.dispatch(RECIPE_GET_RECIPES, { data });
      },
      initialGetRecipesState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_RECIPES_STATE);
      }
    }
  };
</script>

<style lang="scss">
  .v-pagination > li > .v-pagination__navigation {
    display: none;
  }
</style>
