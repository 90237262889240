const intersectMixin = {
  methods: {
    onIntersectActionEnterY() {
      const card = document.querySelectorAll('.slide-fade-y-enter');
      const observe = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            entry.target.classList.toggle(
              'slide-fade-y-enter-active',
              entry.isIntersecting
            );
          });
        },
        {
          threshold: 0.25
          // rootMargin: '50px'
        }
      );

      card.forEach((section) => {
        observe.observe(section);
      });
    },
    onIntersectActionEnterX() {
      const card = document.querySelectorAll('.slide-fade-x-enter');
      const observe = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            entry.target.classList.toggle(
              'slide-fade-x-enter-active',
              entry.isIntersecting
            );
          });
        },
        {
          threshold: 0.25
        }
      );

      card.forEach((section) => {
        observe.observe(section);
      });
    },
    onIntersectActionEnterXOnce() {
      const card = document.querySelectorAll('.slide-fade-x-enter');
      const observe = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('slide-fade-x-enter-active');
            }
          });
        },
        {
          threshold: 0.25
        }
      );

      card.forEach((section) => {
        observe.observe(section);
      });
    },
    onIntersectActionEnterYOnce() {
      const card = document.querySelectorAll('.slide-fade-y-enter');
      const observe = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('slide-fade-y-enter-active');
            }
          });
        },
        {
          threshold: 0.25
        }
      );

      card.forEach((section) => {
        observe.observe(section);
      });
    }
  }
};

export default intersectMixin;
